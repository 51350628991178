import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "Home";
import Locales from "components/Locales";
import { useEffect } from "react";
import { useAppDispatch } from "store";
import { setLocale } from "store/slices/config";

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const language = urlParams.get("hl");
    if (language) {
      dispatch(setLocale(language));
    }
  }, [dispatch]);

  return (
    <Locales>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/store/apps/details" element={<Home />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </Locales>
  );
}

export default App;
