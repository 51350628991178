import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: { locale: string } = {
  locale: "en",
};

// ==============================|| SLICE - SNACKBAR ||============================== //

const config = createSlice({
  name: "config",
  initialState,
  reducers: {
    setLocale(state, action: PayloadAction<string>) {
      state.locale = action.payload;
    },
  },
});

export default config.reducer;

export const { setLocale } = config.actions;
