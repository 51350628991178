// third-party
import { combineReducers } from "redux";

// project imports
import configReducer from "./slices/config";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  config: configReducer,
});

export default reducer;
